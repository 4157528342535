import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

/**
 * Validador / filtro
 * 
 * @version: 1.0
 * @author:  <a href="mailto:jromero@ksoftonline.com">Jorge Romero - KSoft S.A.S.</a>
 * @since 1.0
 */
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate() {
    if (sessionStorage.getItem('currentUser')) return true;
    this.router.navigate(['/', 'login']); return false;
  }
}
/*
Copyright 2018 KSoft S.A.S. All Rights Reserved.
*/
