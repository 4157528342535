import { NgModule, ModuleWithProviders, Optional, SkipSelf, } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MenubarModule } from 'primeng/menubar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccesoService, BASE_PATH, Configuration } from './rest-api';
import { environment } from "src/environments/environment";
import { JwtInterceptor } from "./jwt.interceptor";
import { AuthGuard } from "./auth.guard";
import { LoginComponent } from './login/login.component';
import { InicioComponent } from './inicio/inicio.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [AppComponent, LoginComponent, InicioComponent, LogoutComponent],
  imports: [BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule, HttpClientModule, ButtonModule,
    BrowserAnimationsModule, MessagesModule, MessageModule, MenubarModule, ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production, registrationStrategy: 'registerWhenStable:30000'
    })],
  providers: [AuthGuard, AccesoService, { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<AppModule> {
    return { ngModule: AppModule, providers: [{ provide: Configuration, useFactory: configurationFactory }], };
  }

  constructor(@Optional() @SkipSelf() parentModule: AppModule, @Optional() http: HttpClient) {
    if (parentModule) throw new Error("ApiModule is already loaded. Import in your base AppModule only.");
    if (!http) throw new Error("You need to import the HttpClientModule in your AppModule! \n" +
      "See also https://github.com/angular/angular/issues/20575");
  }
}
