import { Component } from '@angular/core';

import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  auth: boolean = false;
  items: MenuItem[] = [];

  constructor() {
    if (sessionStorage.getItem('currentUser') != undefined) this.auth = true;
    if (this.auth) {
      this.items = [{ label: 'Inicio', icon: 'pi pi-home', routerLink: '/', visible: this.auth },
      { label: 'Leer código QR', icon: 'pi pi-qrcode', routerLink: '/scan-qr/lectura', visible: this.auth },
      { label: 'Registro de conteos o consumos', icon: 'pi pi-file-edit', routerLink: '/scan-qr/registro', visible: this.auth },
      { label: 'Consulta de datos', icon: 'pi pi-chart-bar', routerLink: '/show-data', visible: this.auth },
      { label: 'Consulta de conteos o consumos', icon: 'pi pi-inbox', routerLink: '/show-data/escaneos', visible: this.auth },
      { label: 'Salir', icon: 'pi pi-sign-out', routerLink: '/logout', visible: this.auth },];
    }
  }
}
