<div class="div-base-login">
  <form [formGroup]="form" (ngSubmit)="submit()" class="form">
    <h1>Identificación</h1>
    <p>Por favor ingrese su nombre de usuario y contraseña.</p>
    <div><input type="text" formControlName="username" placeholder="Usuario" /></div>
    <div><input type="password" formControlName="password" placeholder="Contraseña" /></div>
    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
    <p-button label="Ingresar" (onClick)="submit()"></p-button>
  </form>
  <div class="login"></div>
</div>