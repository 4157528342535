import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsuarioVO } from './rest-api';

/**
 * Interceptor
 * 
 * @version: 1.0
 * @author:  <a href="mailto:jromero@ksoftonline.com">Jorge Romero - KSoft S.A.S.</a>
 * @since 1.0
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentUser: UsuarioVO = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
    let authorization: string = sessionStorage.getItem('authorization') || '';
    if (currentUser && authorization) {
      request = request.clone({
        setHeaders: {
          'Authorization': `${authorization}`,
          'X-KSoft-DTR': Date.now().toString(),
          'X-Build': environment.build
        }
      });
    }
    if (authorization != null && currentUser == null) {
      request = request.clone({
        setHeaders: {
          'Authorization': `${authorization}`,
          'X-KSoft-DTR': Date.now().toString(),
          'X-Build': environment.build,
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'X-KSoft-DTR': Date.now().toString(),
          'X-Token': 'none-' + Date.now().toString()
        }
      });
    }
    return next.handle(request);
  }
}
/*
Copyright 2019 KSoft S.A.S. All Rights Reserved.
*/
