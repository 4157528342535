import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { InicioComponent } from './inicio/inicio.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
  { path: 'inicio', component: InicioComponent, canActivate: [AuthGuard] },
  { path: "", component: InicioComponent, canActivate: [AuthGuard] },
  { path: 'scan-qr', loadChildren: () => import('./scan-qr/scan-qr.module').then(m => m.ScanQRModule), canActivate: [AuthGuard] },
  { path: 'show-data', loadChildren: () => import('./show-data/show-data.module').then(m => m.ShowDataModule), canActivate: [AuthGuard] },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
