import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { AccesoService, UsuarioVO } from '../rest-api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  form!: FormGroup;
  msgs: Message[] = [];
  loading: boolean = false;

  constructor(private fb: FormBuilder, private api: AccesoService, private router: Router,
    private mssgService: MessageService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      username: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required])
    });
  }

  submit(): void {
    this.msgs = [];
    this.mssgService.clear();
    if (this.form.valid) {
      let vo: UsuarioVO = this.form.value;
      this.api.validar(vo).subscribe({
        next: (data) => {
          sessionStorage.setItem('currentUser', JSON.stringify(data));
          sessionStorage.setItem('authorization', JSON.stringify(data.key));
          this.mssgService.add({ severity: 'info', summary: 'Autenticación correcta' });
          this.router.navigate(['/', 'inicio'], { replaceUrl: true }).then(() => window.location.reload());
        }, error: (err) => {
          this.mssgService.add({ severity: 'error', summary: 'Error en autenticación', detail: 'Se presentó un error en la autenticación' });
        }, complete: () => { this.loading = true; }
      });
    }
    else this.msgs.push({ severity: 'error', summary: 'Error en datos', detail: 'Por favor ingrese todos los campos solicitados' });
  }

}
