<div class="inicio-base">
  <div><a routerLink="/scan-qr/lectura" routerLinkActive="active"><i class="pi pi-qrcode icon-home"></i><span>Consultar
        lote</span></a></div>
  <div><a routerLink="/scan-qr/registro" routerLinkActive="active"><i
        class="pi pi-file-edit icon-home"></i><span>Registro
        de conteos o consumos</span></a></div>
  <div><a routerLink="/show-data" routerLinkActive="active"><i class="pi pi-chart-bar icon-home"></i><span>Consulta
        de resultados</span></a></div>
  <div><a routerLink="/show-data/escaneos" routerLinkActive="active"><i class="pi pi-inbox icon-home"></i><span>Consulta
        de conteos o consumos</span></a></div>
</div>